export class ApiUrls {

  private static BaseApiUrl = '/api/v1/';

  // Projects
  public static BaseProject = `${ApiUrls.BaseApiUrl}project/`;

  public static GetAllProjects = `${ApiUrls.BaseProject}list`;
  public static GetProjectById = `${ApiUrls.BaseProject}`;
  public static GetProjectKPI = `${ApiUrls.BaseProject}kpi/`;
  public static GetFullProjectById = `${ApiUrls.BaseProject}page/`;
  public static GetAlsoLikeProjects = `${ ApiUrls.BaseProject }also_like`;
  public static PostProposal = `${ ApiUrls.BaseProject }proposal`;

  // Articles
  public static GetProjectArticles = `${ApiUrls.BaseApiUrl}article/`;

  // Volunteering
  public static BaseVolunteering = `${ApiUrls.BaseApiUrl}volunteering/`;

  public static GetVolunteeringOffer = `${ApiUrls.BaseVolunteering}volunteering_offer/`;
  public static GetListVolunteeringProjects = `${ApiUrls.BaseVolunteering}list/`;
  public static GetAllMissions = `${ApiUrls.BaseVolunteering}all`;
  public static SetApplicantVolunteeringProject = `${ApiUrls.BaseVolunteering}add_applicant`;
  public static PostCheckAccountVolunteeringApplications = `${ApiUrls.BaseVolunteering}check_account`;
  public static PostListRelatedEmployees = `${ApiUrls.BaseVolunteering}employees`;
  public static PostShareVolunteeringOffer = `${ApiUrls.BaseVolunteering}share`;
  public static PostExternalClick = `${ApiUrls.BaseVolunteering}external_click`;
  public static GetCollectiveActivity = `${ApiUrls.BaseVolunteering}collective_activity/`;
  public static GetTeamBuilding = `${ApiUrls.BaseVolunteering}team_building/`;
  public static SendFeedback = `${ApiUrls.BaseVolunteering}send_feedback`;
  public static SendActivityProposal = `${ApiUrls.BaseVolunteering}create_collective_activity`;
  public static SignUpCollectiveActivity = `${ApiUrls.BaseVolunteering}apply_collective_activity`;
  public static SignUpTeamBuilding = `${ApiUrls.BaseVolunteering}apply_team_building`;
  public static SignUpOffer = `${ApiUrls.BaseVolunteering}apply_offer`;
  public static CancelOfferRegistration = `${ApiUrls.BaseVolunteering}cancel_volunteering_offer_application`;
  public static CancelActivityRegistration = `${ApiUrls.BaseVolunteering}cancel_collective_activity_application`;
  public static CancelTeamBuildingRegistration = `${ApiUrls.BaseVolunteering}cancel_team_building_application`;
  public static UpdateActivityProposal = `${ApiUrls.BaseVolunteering}update_collective_activity`;
  public static DeleteCollectiveActivity = `${ApiUrls.BaseVolunteering}delete_collective_activity`;

  // Newsletter
  public static BaseNewsletter = `${ApiUrls.BaseApiUrl}newsletter/`;

  public static PostSubscribeNewsletter = `${ApiUrls.BaseNewsletter}subscribe`;

  // Subscription
  public static BaseSubscription = `${ApiUrls.BaseApiUrl}subscription/`;

  public static GetUserSubscription = `${ApiUrls.BaseSubscription}get`;
  public static DeleteUserSubscription = `${ApiUrls.BaseSubscription}stop`;

  // Vote
  public static BaseVote = `${ApiUrls.BaseApiUrl}vote/`;

  public static VoteProject = `${ApiUrls.BaseVote}vote`;
  public static UnvoteProject = `${ApiUrls.BaseVote}unvote`;
  public static GetVotedProjects = `${ApiUrls.BaseVote}voted`;

  // User
  public static BaseAccount = `${ApiUrls.BaseApiUrl}account/`;

  public static GetCurrentUser = `${ ApiUrls.BaseAccount }current`;
  public static GetUserLastFourDigits = `${ApiUrls.BaseAccount}payment_info`;
  public static GetClockInfo = `${ApiUrls.BaseAccount}clock`;
  public static PostAccountAuthCheck = `${ApiUrls.BaseAccount}sso_account_check`;
  public static PostRegisterUser = `${ApiUrls.BaseAccount}register`;
  public static PostLogInUser = `${ ApiUrls.BaseAccount }login`;
  public static PostLogOutUser = `${ ApiUrls.BaseAccount }logout`;
  public static PostSsoVerification = `${ApiUrls.BaseAccount}sso_verification`;
  public static PostSsoSignupVerification = `${ApiUrls.BaseAccount}sso_signup_verification`;
  public static PutSaveCauses = `${ApiUrls.BaseAccount}set_cause_preference`;
  public static PutSaveContinents = `${ApiUrls.BaseAccount}set_continent_preference`;
  public static PutSaveCountry = `${ApiUrls.BaseAccount}set_tax_country`;
  public static GetDonations = `${ApiUrls.BaseAccount}donations`;
  public static GetFullImpact = `${ApiUrls.BaseAccount}full_impact`;
  public static PutChangeEmail = `${ApiUrls.BaseAccount}change_email`;
  public static PutChangePassword = `${ApiUrls.BaseAccount}change_password`;
  public static PostResetPassword = `${ApiUrls.BaseApiUrl}password_reset/`;
  public static PostResetPasswordConfirm = `${ApiUrls.BaseApiUrl}password_reset/confirm/`;
  public static GetProfileSettings = `${ApiUrls.BaseAccount}profile_settings`;
  public static PutPaymentMethod = `${ApiUrls.BaseAccount}edit_payment_method`;
  public static GetSupportedOwnMoney = `${ApiUrls.BaseAccount}supported_own_money`;
  public static GetSupportedCommunities = `${ApiUrls.BaseAccount}supported_communities`;
  public static GetProfilePicture = `${ApiUrls.BaseAccount}view_profile_picture`;
  public static UploadProfilePicture = `${ ApiUrls.BaseAccount }upload_profile_picture`;
  public static GetHomePageInfo = `${ ApiUrls.BaseAccount }homepage`;

  // Company
  public static BaseCompany = `${ApiUrls.BaseApiUrl}company/`;

  public static Company = `${ApiUrls.BaseCompany}`;
  public static Brands = `${ ApiUrls.BaseCompany }brands`;
  public static Program = `${ApiUrls.BaseApiUrl}customerspledge/`;
  public static ProgramImpact = `${ApiUrls.Program}impact/`;
  public static ProgramVote = `${ApiUrls.Program}vote`;
  public static MatchingGift = `${ApiUrls.BaseApiUrl}matchinggift/`;
  public static MatchingGiftImpact = `${ApiUrls.MatchingGift}impact/`;
  public static BrandsImpact = `${ApiUrls.BaseCompany}impact`;
  public static AccessibleCompanies = `${ApiUrls.BaseCompany}accessible_companies`;

  // Crisis Events
  public static CrisisEvent = `${ApiUrls.BaseApiUrl}crisisevent/`;

  public static CheckPayment = `${ApiUrls.CrisisEvent}check_payment`;

  // No-KPI Projects
  public static NoKpi = `${ApiUrls.BaseApiUrl}nokpi/`;
  public static GetNoKpiProjectById = `${ApiUrls.NoKpi}project/`;

  // Error Handling
  public static BaseBackendLog = `${ApiUrls.BaseApiUrl}backendlog/`;

  public static GetHttpErrors = `${ApiUrls.BaseBackendLog}get_http_errors`;
  public static PostHttpErrors = `${ApiUrls.BaseBackendLog}add_http_error`;
  public static PostFrontendLogEntry = `${ApiUrls.BaseBackendLog}add_frontend_log`;

  // Payments v2
  public static BasePayments = `${ApiUrls.BaseApiUrl}payment/`;

  public static PostCreateLoggedPayment = `${ApiUrls.BasePayments}create_payment_logged`;
  public static PostCreateUnloggedPayment = `${ ApiUrls.BasePayments }create_payment_unlogged`;
  public static PostGetPaymentStatus = `${ ApiUrls.BasePayments }get_payment_status`;

  // Push Notifications
  public static BasePushNotifications = `${ ApiUrls.BaseApiUrl }pushnotification/`;

  public static PostGetCurrentPreferences = `${ ApiUrls.BasePushNotifications }current`;
  public static PostChangePreferences = `${ ApiUrls.BasePushNotifications }change`;
  public static PostSetPlayerId = `${ ApiUrls.BasePushNotifications }player_id`;

  // LAC
  public static BaseLac = `${ ApiUrls.BaseApiUrl }lac/`;

  public static GetLacCampaign = `${ ApiUrls.BaseLac }campaign_activity`;
  public static GetLacFeed = `${ ApiUrls.BaseLac }get_feed`;
  public static GetLacReadingContent = `${ ApiUrls.BaseLac }reading_content`;
  public static GetLacQuiz = `${ ApiUrls.BaseLac }quiz`;
  public static PostCompleteLacReadingContent = `${ ApiUrls.BaseLac }complete_reading_content`;
  public static PostCompleteLacQuiz = `${ ApiUrls.BaseLac }complete_quiz`;
  public static GetChallenge = `${ ApiUrls.BaseLac }challenge`;
  public static PostCompleteChallenge = `${ ApiUrls.BaseLac }complete_challenge`;
  public static PostShareChallenge = `${ ApiUrls.BaseLac }share_challenge`;
  public static PostChallenges = `${ ApiUrls.BaseLac }challenges`;
  public static PostChallengePictureUpload = `${ ApiUrls.BaseLac }picture_upload`;
  public static PostLearningContent = `${ ApiUrls.BaseLac }learning_content`;
  public static PostLikeFeedContent = `${ ApiUrls.BaseLac }like_content`;
  public static PostLikeFeedComment = `${ApiUrls.BaseLac}like_comment`;
  public static PostLikeFeedReply = `${ApiUrls.BaseLac}like_reply`;
  public static PostCreateFeedComment = `${ApiUrls.BaseLac}create_comment`;
  public static PostRemoveFeedComment = `${ApiUrls.BaseLac}remove_comment`;
  public static PostCreateFeedReply = `${ApiUrls.BaseLac}create_reply`;
  public static PostRemoveFeedReply = `${ApiUrls.BaseLac}remove_reply`;
  public static PostBadges = `${ApiUrls.BaseLac}badges`;
  public static PostUserPublic = `${ApiUrls.BaseLac}user_public`;
  public static PostLastWeekChallenges = `${ApiUrls.BaseLac}last_week_challenges`;
  public static PostUsers = `${ApiUrls.BaseLac}users`;
  public static PostTeams = `${ApiUrls.BaseLac}teams`;
  public static PostEndLacInfo = `${ApiUrls.BaseLac}account_end_campaign`;

  // Notifications
  public static BaseNotifications = `${ApiUrls.BaseApiUrl}notification/`;

  public static GetNotifications = `${ApiUrls.BaseNotifications}get_all`;
  public static GetPost = `${ApiUrls.BaseNotifications}get`

  // Systems management endpoints
  public static PostGetCurrentEnvironment = `${ ApiUrls.BaseApiUrl }monitoring/environment`;

  // SAML
  public static SAMLLogin = `${ ApiUrls.BaseApiUrl }saml/login/`;

}
