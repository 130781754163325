import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { NgxMaskModule } from 'ngx-mask';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatSelectModule } from '@angular/material/select';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HoverDirective } from '../directives/hover.directive';
import { CarouselComponent } from './carousel/carousel.component';
import { ProjectCarouselComponent } from './project-carousel/project-carousel.component';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { CeilPipe } from '../pipes/ceil.pipe';
import { DeductPipe } from '../pipes/deduct.pipe';
import { MultiplyPipe } from '../pipes/multiply.pipe';
import { DividePipe } from '../pipes/divide.pipe';
import { RoundImpactPipe } from '../pipes/round-impact.pipe';
import { SafeurlPipe } from '../pipes/safeurl.pipe';
import { CauseDialogComponent } from '../profile-module/profile/cause-dialog/cause-dialog.component';
import { ContinentDialogComponent } from '../profile-module/profile/continent-dialog/continent-dialog.component';
import { CountryDialogComponent } from '../profile-module/profile/country-dialog/country-dialog.component';
import {
  ChangePasswordDialogComponent
} from '../profile-module/profile/change-password-dialog/change-password-dialog.component';
import {
  ChangeEmailDialogComponent
} from '../profile-module/profile/change-email-dialog/change-email-dialog.component';
import {
  EditPaymentDialogComponent
} from '../profile-module/profile/edit-payment-dialog/edit-payment-dialog.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { ShowPasswordDirective } from '../directives/show-password.directive';
import { ShareTooltipComponent } from './share-tooltip/share-tooltip.component';
import { TooltipDirective } from '../directives/tooltip.directive';
import { SubscribeBannerComponent } from './subscribe-banner/subscribe-banner.component';
import { SubscribedDialogComponent } from './subscribed-dialog/subscribed-dialog.component';
import { BrandHeaderComponent } from './header/brand-header/brand-header.component';
import { BrandFooterComponent } from './footer/brand-footer/brand-footer.component';
import { RadarChartComponent } from '../your-impact-module/your-impact/chart/radar-chart.component';
import { GeochartComponent } from '../your-impact-module/your-impact/geochart/geochart.component';
import { BrandCardComponent } from './brand-card/brand-card.component';
import { ProjectBrandCarouselComponent } from './project-brand-carousel/project-brand-carousel.component';
import { ImpactPopupComponent } from './impact-popup/impact-popup.component';
import { CustomSpinnerComponent } from './custom-spinner/custom-spinner.component';
import { BrandImpactPopupComponent } from './brand-impact-popup/brand-impact-popup.component';
import { StripeCardFormComponent } from './stripe-card-form/stripe-card-form.component';
import { SoftAskModalComponent } from './modals/soft-ask-modal/soft-ask-modal.component';
import { NsButtonComponent } from './ns-components/ns-button/ns-button.component';
import { NsNumberInputComponent } from './ns-components/ns-number-input/ns-number-input.component';
import { DonationAmountSelectorComponent } from './donation-amount-selector/donation-amount-selector.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SquareMetersPipe } from '../pipes/square-meters.pipe';
import { GeomapComponent } from './geomap/geomap.component';
import { NsTooltipDirective } from '../directives/ns-tooltip.directive';
import { NsEmailListShareComponent } from './modals/ns-email-list-share/ns-email-list-share.component';
import { NsSimpleInfoModalComponent } from './modals/ns-simple-info-modal/ns-simple-info-modal.component';
import {
  NsSimpleConfirmationModalComponent
} from './modals/ns-simple-confirmation-modal/ns-simple-confirmation-modal/ns-simple-confirmation-modal.component';
import { NotificationsCardComponent } from './notifications-card/notifications-card.component';
import { NotificationsModalComponent } from './header/notifications-modal/notifications-modal.component';
import { ProposeInitiativeModalComponent } from './modals/propose-initiative-modal/propose-initiative-modal.component';
import { NsTruncateWithEllipsisDirective } from '../directives/ns-vertical-text-ellipsis.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NoCopyPasteDirective } from '../directives/no-copy-paste.directive';
import { WelcomeOnboardingComponent } from './header/welcome-onboarding/welcome-onboarding.component';
import { BaseComponent } from './base/base.component';
import { VolunteeringOnboardingComponent } from './header/volunteering-onboarding/volunteering-onboarding.component';
import { SupportOnboardingComponent } from './header/support-onboarding/support-onboarding.component';
import { FirstVoteOnboardingComponent } from './header/first-vote-onboarding/first-vote-onboarding.component';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';
import { VolunteeringCardComponent } from '../volunteering-module/volunteering-card/volunteering-card.component';
import { NsUsersListModalComponent } from './modals/ns-users-list-modal/ns-users-list-modal.component';
import { MissionCardComponent } from '../volunteering-module/volunteering/common/mission-card/mission-card.component';
import { VolBackButtonComponent } from '../volunteering-module/volunteering/common/vol-back-button/vol-back-button.component';
import { NsSimpleFooterComponent } from './ns-components/simple-footer/simple-footer.component';


export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json?' + Date.now());
}

export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return '';
  }
}


@NgModule({
  declarations: [
    HoverDirective,
    CarouselComponent,
    ProjectCarouselComponent,
    CapitalizePipe,
    CeilPipe,
    DeductPipe,
    MultiplyPipe,
    DividePipe,
    RoundImpactPipe,
    SafeurlPipe,
    SquareMetersPipe,
    CauseDialogComponent,
    ContinentDialogComponent,
    CountryDialogComponent,
    ChangePasswordDialogComponent,
    ChangeEmailDialogComponent,
    EditPaymentDialogComponent,
    ProjectCardComponent,
    ShowPasswordDirective,
    ShareTooltipComponent,
    TooltipDirective,
    SubscribeBannerComponent,
    SubscribedDialogComponent,
    BrandHeaderComponent,
    BrandFooterComponent,
    RadarChartComponent,
    GeochartComponent,
    BrandCardComponent,
    ProjectBrandCarouselComponent,
    ImpactPopupComponent,
    CustomSpinnerComponent,
    BrandImpactPopupComponent,
    StripeCardFormComponent,
    SoftAskModalComponent,
    StripeCardFormComponent,
    NsButtonComponent,
    NsNumberInputComponent,
    DonationAmountSelectorComponent,
    GeomapComponent,
    NsTooltipDirective,
    NsTruncateWithEllipsisDirective,
    NsEmailListShareComponent,
    NsSimpleInfoModalComponent,
    NsSimpleConfirmationModalComponent,
    NotificationsCardComponent,
    NotificationsModalComponent,
    ProposeInitiativeModalComponent,
    NoCopyPasteDirective,
    WelcomeOnboardingComponent,
    BaseComponent,
    VolunteeringOnboardingComponent,
    SupportOnboardingComponent,
    FirstVoteOnboardingComponent,
    VolunteeringCardComponent,
    NsUsersListModalComponent,
    MissionCardComponent,
    VolBackButtonComponent,
    NsSimpleFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgApexchartsModule,
    MatDialogModule,
    MatSelectModule,
    NgxHmCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterComponent,
    MatSliderModule,
    NgxMaskModule,
    MatExpansionModule,
    MatBottomSheetModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MissingTranslationService},
    }),
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        extendsFromRoot: true
      },
    }),
  ],
  providers: [
    { provide: MatDialogRef, useValue: {
        close: (dialogResult: any) => {
        }
      }
    },
    {provide: MAT_DIALOG_DATA, useValue: []}
  ],
    exports: [
      NgApexchartsModule,
      MatDialogModule,
      MatSelectModule,
      NgxHmCarouselModule,
      FormsModule,
      ReactiveFormsModule,
      PasswordStrengthMeterComponent,
      MatSliderModule,
      NgxMaskModule,
      MatExpansionModule,
      TranslateModule,
      HoverDirective,
      CarouselComponent,
      ProjectCarouselComponent,
      ProjectBrandCarouselComponent,
      CapitalizePipe,
      CeilPipe,
      DeductPipe,
      MultiplyPipe,
      DividePipe,
      RoundImpactPipe,
      SafeurlPipe,
      SquareMetersPipe,
      CauseDialogComponent,
      ContinentDialogComponent,
      CountryDialogComponent,
      ChangePasswordDialogComponent,
      ChangeEmailDialogComponent,
      EditPaymentDialogComponent,
      ProjectCardComponent,
      ShowPasswordDirective,
      ShareTooltipComponent,
      TooltipDirective,
      NsTruncateWithEllipsisDirective,
      SubscribeBannerComponent,
      SubscribedDialogComponent,
      BrandHeaderComponent,
      BrandFooterComponent,
      RadarChartComponent,
      GeochartComponent,
      BrandCardComponent,
      CustomSpinnerComponent,
      StripeCardFormComponent,
      NsButtonComponent,
      NsNumberInputComponent,
      DonationAmountSelectorComponent,
      SoftAskModalComponent,
      MatBottomSheetModule,
      GeomapComponent,
      NsTooltipDirective,
      NotificationsCardComponent,
      ProposeInitiativeModalComponent,
      NgxSkeletonLoaderModule,
      NoCopyPasteDirective,
      WelcomeOnboardingComponent,
      VolunteeringOnboardingComponent,
      SupportOnboardingComponent,
      FirstVoteOnboardingComponent,
      VolunteeringCardComponent,
      NsUsersListModalComponent,
      MissionCardComponent,
      VolBackButtonComponent,
      NsSimpleFooterComponent
    ]
})
export class SharedModule { }
