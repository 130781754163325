import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, zip } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import * as Projects from '../shared/projects.model';
import { ProjectListResponse } from '../shared/projects.model';
import * as User from '../shared/user.model';
import { AuthService } from './auth.service';
import { FullImpactResponse } from '../shared/impact.model';
import { UserService } from './user.service';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  projects: Projects.PartialProject[];
  /** Retrieves cached supported projects from last endpoint call (Always called on login) **/
  supportedProjects$ = new BehaviorSubject<Projects.PartialProject[]>([]);
  supportedProjectsIds$ = new BehaviorSubject<number[]>([]);
  projectsOwnMoney$ = new BehaviorSubject<Projects.PartialProject[]>([]);
  projectsOwnMoneyIds$ = new BehaviorSubject<number[]>([]);
  projectsCommunities$ = new BehaviorSubject<Projects.PartialProject[]>([]);
  projectsCommunitiesIds$ = new BehaviorSubject<number[]>([]);
  fullProjectInfo = new BehaviorSubject<Projects.FullProject>(null);
  noKpiProject = new BehaviorSubject<Projects.NoKpiProject>(null);
  clockInfo = new BehaviorSubject<User.ClockInfo>(null);
  projectArticles$ = new BehaviorSubject<Projects.ProjectArticle[]>(null);
  updatedVotes$ = new Subject<void>();
  onVoteWithFiveVotesAvailable$ = new Subject<void>();

  additionalMarginBottomFooter = new BehaviorSubject(0);

  constructor(private apiService: ApiService,
              private userService: UserService,
              private injector: Injector,
              private authService: AuthService) {
    this.authService.isUserLogged
      .pipe(
        switchMap(value => value ? zip(this.getSupportedProjects()) : of([]))
      )
      .subscribe();
  }

  getProjects(): Observable<Projects.PartialProject[]> {
    const headerService = this.injector.get(HeaderService);
    if (this.projects) {
      return of(this.projects);
    } else {
      return this.apiService.getAllProjects()
        .pipe(
          tap((response: ProjectListResponse) => {
            if (!response.support_onboarding) {
              headerService.$isSupportOnboardingDone.next(false);
            }
          }),
          map((response: ProjectListResponse) => response.results),
          tap(projects => this.projects = projects)
        );
    }
  }

  /**
   * Fetches user supported projects. __IMPORTANT__ This data is always retrieved upon login
   *  and cached in supportedProjects$. To prevent unnecessary API calls, it's recommended to use supportedProjects$ for accessing this data.
   **/
  getSupportedProjects(): Observable<Projects.PartialProject[]> {
    return this.apiService.getSupportedProjects()
      .pipe(
        map(response => response.results),
        tap(projects => this.supportedProjects$.next(projects)),
        tap(project => this.supportedProjectsIds$.next(project.map(item => item.id)))
      );
  }

  getFullProjectInfo(projectId: number) {
    return this.apiService.getFullProjectInfo(projectId)
      .pipe(
        tap(value => this.fullProjectInfo.next(value))
      );
  }

  getNoKpiProject(projectId: number) {
    return this.apiService.getNoKpiProject(projectId)
      .pipe(
        tap(value => this.noKpiProject.next(value))
      );
  }

  getProjectForDialog(projectId: number) {
    return this.apiService.getProjectInfo(projectId);
  }

  getProjectKPI(projectId: number) {
    return this.apiService.getProjectKPI(projectId);
  }

  getRelatedProjects(projectId?: number) {
    return this.apiService.getAlsoLikeProjects(projectId)
      .pipe(
        map(response => response.projects)
      );
  }

  getClockInfo() {
    return this.apiService.getClockInfo()
      .pipe(
        tap(value => this.clockInfo.next(value))
      );
  }

  getVotesLeft() {
    return this.apiService.getClockInfo()
      .pipe(
        map(value => {
          return value.votes_left;
        })
      );
  }

  voteProject(projectId: number) {
    const headerService = this.injector.get(HeaderService);
    return this.apiService.voteProject(projectId)
      .pipe(
        tap((response) => {
          // ONBOARDING: We check if it's the very first vote of the user, to display and informative modal
          if (!response.first_vote_onboarding) {
            headerService.$isFirstVoteOnboardingDone.next(false);
          }
        }),
        map(response => response.results),
        tap(() => this.updatedVotes$.next()),
        tap(projects => this.supportedProjects$.next(projects)),
        tap(project => this.supportedProjectsIds$.next(project.map(item => item.id)))
      );
  }

  unvoteProject(projectId: number) {
    return this.apiService.unvoteProject(projectId)
      .pipe(
        map(response => response.results),
        tap(() => this.updatedVotes$.next()),
        tap(projects => this.supportedProjects$.next(projects)),
        tap(project => this.supportedProjectsIds$.next(project.map(item => item.id)))
      );
  }

  isVoted(projectId: number): boolean {
    return this.supportedProjectsIds$.value.includes(projectId);
  }

  getProjectArticles(projectId: number) {
    return this.apiService.getProjectArticles(projectId)
      .pipe(
        map(response => response.results),
        tap(projectArticles => this.projectArticles$.next(projectArticles))
      );
  }

  getUserRelatedEmployees(userId: number) {
    return this.apiService.getUserRelatedEmployees(userId);
  }

  getFullImpact(): Observable<FullImpactResponse> {
    return this.userService.getFullImpact();
  }

  getProjectsOwnMoney(): Observable<Projects.PartialProject[]> {
    return this.apiService.getProjectsOwnMoney()
      .pipe(
        map(response => response.results),
        tap(projects => this.projectsOwnMoney$.next(projects)),
        tap(project => this.projectsOwnMoneyIds$.next(project.map(item => item.id)))
      );
  }

  getProjectsCommunities(): Observable<Projects.PartialProject[]> {
    return this.apiService.getProjectsCommunities()
      .pipe(
        map(response => response.results),
        tap(projects => this.projectsCommunities$.next(projects)),
        tap(project => this.projectsCommunitiesIds$.next(project.map(item => item.id)))
      );
  }

  sendProposal(proposal: Projects.Proposal): Observable<any> {
    return this.apiService.postProposal(proposal);
  }
}
