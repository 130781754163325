import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injectable, Injector, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CorePageComponent } from './core-page/core-page.component';
import { ImpactOrganizationComponent } from './impact-organization/impact-organization.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { LogInComponent } from './auth/log-in/log-in.component';
import { SamlAuthComponent } from './auth/saml-auth/saml-auth.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { CapsLockDirective } from './directives/caps-lock.directive';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './auth/reset-password-confirm/reset-password-confirm.component';
import { HeaderLoggedComponent } from './shared/header/logged/header-logged.component';
import { HeaderUnloggedComponent } from './shared/header/unlogged/header-unlogged.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LandingPageComponent } from './core-page/landing-page/landing-page.component';
import { PageFourOFourComponent } from './page-four-o-four/page-four-o-four.component';
import {
  CredentialChangedDialogComponent
} from './profile-module/profile/credential-changed-dialog/credential-changed-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import {
  GoogleInitOptions,
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  MicrosoftLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { environment } from '../environments/environment';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import 'hammerjs';
import { VettingProcessComponent } from './vetting-process/vetting-process.component';
import { PublicModule } from './public-module/public.module';
import { NgxMaskModule } from 'ngx-mask';
import { ChartsModule } from '@carbon/charts-angular';
import { MicrosoftOptions } from '@abacritt/angularx-social-login/providers/microsoft-login-provider';
import { SamlRedirectComponent } from './auth/saml-redirect/saml-redirect.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json?' + Date.now());
}

export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return '';
  }
}

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-store, no-cache, must-revalidate',
        Pragma: 'no-store'
      }
    });
    return next.handle(authReq);
  }
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang(window.location.href.split('/')[3]);
      translate.use(langToSet).subscribe(() => {
      }, () => {
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    CorePageComponent,
    VettingProcessComponent,
    ImpactOrganizationComponent,
    SignUpComponent,
    LogInComponent,
    SamlRedirectComponent,
    TermsOfServiceComponent,
    CapsLockDirective,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent,
    HeaderLoggedComponent,
    HeaderUnloggedComponent,
    FooterComponent,
    LandingPageComponent,
    PageFourOFourComponent,
    CredentialChangedDialogComponent,
    PrivacyPolicyComponent,
    SamlAuthComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
    }),
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    SocialLoginModule,
    ChartsModule,
    PublicModule,
    GoogleSigninButtonModule,
    MatNativeDateModule,
    MatDatepickerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('1038195076962-5e27qdhme29s294sl96l9vmcm5j182hn.apps.googleusercontent.com',
              {oneTapEnabled: false} as GoogleInitOptions),
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(environment.noosMsalClientId, {
              // Using 'common' instead of a specific tenant ID allows users from any Azure AD tenant to sign in
              tenantId: 'common',
              redirect_uri: environment.noosMsalRedirectUri,
              authority: `https://login.microsoftonline.com/common`
            } as MicrosoftOptions)
          }
        ],
      } as SocialAuthServiceConfig,
    },
    [provideZxvbnServiceForPSM()]
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule {
}
